.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.error {
  display: block;
}
