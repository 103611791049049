.childrenContainer {
  position: relative;
  min-width: 150px;
  .actionButtons {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    margin-top: 0.45rem;
    z-index: 100;
    .actionButton {
      width: 30px;
      height: 30px;
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.labelContainer {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.1rem;
  &:hover:not(.disabled) {
    cursor: text;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.05);
  }
}
