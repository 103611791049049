@import '../../scss/variables';
@import '../../scss/vendors/variables';

$footer-bc-color: #fff;
$footer-color: #1f3c47;
$header-bc-color: #fff;
$header-color: #000;
$button-bc-color: #e0e6eb;
$button-bc-color-hover: #d1dae1;
$border-color: rgba(0, 0, 0, 0.25);
$chevron-color: #1f3c47;
$conversation-bc-color: #2fa5d9;
$white: #fff;

* {
  padding: 0;
  margin: 0;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 207px);
  border-radius: 0.3rem;
  box-shadow: $box-shadow-1;
  overflow: hidden;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

// Conversation History Styles
.historyHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 46px;
  height: 100%;
  background-color: $header-bc-color;
  color: $header-color;
  border-bottom: 1px solid $border-color;

  .handoverSwitch {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
  }
}

.historyContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

// Sidebar Styles
.sidebarContainer {
  display: flex;
  flex-flow: column;
  width: 450px;
}

.sidebarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 49px;
  padding: 12px;
  background-color: $header-bc-color;
  color: $header-color;
  border-bottom: 1px solid $border-color;
  .filters {
    position: absolute;
    right: 10px;
  }
  .totalConversationsBadge {
    padding: 2px 5px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 0.25em;
    margin-left: 5px;
  }
}

.filtersDropdownToggle {
  background: $button-bc-color;
  color: $chevron-color;
  &:hover {
    background: $button-bc-color-hover;
  }
}

.filtersDropdownMenu {
  width: 330px;
  .dropdownItem {
    display: block;
    width: 100%;
    clear: both;
    white-space: nowrap;
    padding: 10px 20px;
    border: 0;
    &:first-child {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }
    &:last-child {
      border-top: 1px solid $secondary;
    }
  }
  .resetButton {
    display: flex;
    justify-content: flex-end;
  }
  .handoveredSwitch {
    display: flex;
    align-items: center;
  }
  .dateRangePicker {
    white-space: pre-wrap;
  }
}

.sidebarBody {
  position: relative;
  height: 100%;
  width: 450px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.01);
  .noConversations,
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $dark;
    overflow: hidden;
  }
}

.noConversationHistoryFound {
  width: 100%;
  margin: 50px;
}

.noMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark;
  width: 100%;
  height: calc(100% -100px);
}

.sidebarRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: top;
  position: relative;
  padding: 0.75rem;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease-in-out;
  &.selectedRow {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover {
    background-color: rgba(3, 174, 239, 0.33);
  }
  img {
    float: left;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .statusIcon {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: rgb(160, 160, 160);
    border-radius: 50%;
    border: 1.5px solid #fff;
    left: 45px;
    top: 45px;
    &.online {
      background-color: $status-online;
    }
    &.offline {
      background-color: $status-offline;
    }
    &.waiting {
      background-color: $status-waiting;
    }
  }
  .messageInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .userName {
      margin: 0;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dateOfLastMessage {
      margin: 0;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .platformLogo {
    .liveChat {
      width: 25px;
      height: 29px;
      margin: 0 0 0 10px;
      padding: 0;
    }
    .googleHangouts {
      font-size: 25px;
      background: -webkit-linear-gradient(#1ba261, #1f8456);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .facebookMessenger {
      font-size: 25px;
      background: -webkit-linear-gradient(#00c6ff, #0078ff);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .viber {
      font-size: 25px;
      background: #665cac;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.sidebarFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 14px;
  background-color: $footer-bc-color;
  color: $footer-color;
  border-top: 1px solid $border-color;
}

.historyFooter {
  // @extend .sidebarFooter;
  border-top: 1px solid $border-color;
  background-color: $footer-bc-color;
  display: flow-root;

  .handoverMessageForm {
    width: 100%;
    position: relative;
    display: inline-flex;
    flex: 1;
    height: 49px;

    .handoverMessageInput {
      width: 90%;
      // height: 78%;
      border: none;
      padding: 10px;
      border-radius: 0.25rem 0 0 0.25rem;
    }
    .sendMessageButton {
      background-color: $button-bc-color;
      width: 45px;
      margin: 10px 15px;
      height: 28px;
      border: none;
      border-radius: 0 0.25rem 0.25rem 0;
    }
    .emojiPickerToggler {
      position: absolute;
      right: 8%;
      top: 27%;
      border: none;
      padding: 0;
      color: $footer-color;
      background: transparent;
    }
    .personaToggler {
      width: 40px;
      right: 11%;
      top: 22%;
      border: none;
      padding: 0;
      color: $footer-color;
      background: transparent;
    }

    .alert {
      padding: 0.5rem 0.3rem;
      margin: 10px 10px;
      color: rgb(128, 128, 128);
      align-items: center;
      display: flex;
    }
  }
  .emojiPicker {
    position: absolute;
    bottom: 45px;
    right: 0;
  }
}

.navButton {
  background-color: $button-bc-color;
  width: 50px;
  height: 100%;
  border: none;
  border-radius: 0.25rem;
  &:hover {
    background-color: $button-bc-color-hover;
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: $button-bc-color;
    }
  }
  i {
    color: $chevron-color;
  }
}

.refreshButton {
  @extend .navButton;
}

.pages {
  width: 25%;
  text-align: center;
}

.personaSelectorButton {
  width: 36px;
  border-radius: 50% !important;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  margin: 10px 10px;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: contain;
  }
}

.personaDropdownItem {
  padding: 10px;
}

.personaDetails {
  display: flex;
  align-items: center;
  padding: 0;
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  p {
    margin: 0 0 0 10px;
  }
}

//if no user , top up alert box style
.user_box {
  margin: 0 !important;
  width: 197px;
}
